import { SelectableValue } from "@grafana/data";
import { TAGS } from "../constants";

export function capitalizeFirstLetter(string: String) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function generateOptions(optionsArray: Array<String | SelectableValue>) {
  // takes array of lowercase keys and formats them to be select options
  // pass an array of options and they'll be formatted for grafana-ui's select component. option examples:
  //  <string>: returns {value: <string>, label: <String (first letter capitalized)>}
  //  {value, label}: returns the input object unchanged
  //  {value, description}: returns an object with value and description unchanged, but adds a label
  return optionsArray.map(option => {
    const optionObj: SelectableValue = typeof option === 'string' ? {value: option} : option // convert string options into an obj
    return {
      ...optionObj, // value, description, and imgUrl will now be sent through unchanged.
      label: optionObj?.label || capitalizeFirstLetter(optionObj.value) // compute the label if one wasn't passes
    }
  })
}

export function getTagObjFromValue(tagValue: string) {
  return [...TAGS.pressure, ...TAGS.chemistry].find(tagObj => tagObj.value === tagValue) || {}
}
