import { PanelPlugin } from '@grafana/data';
import { MainPanel } from './components/MainPanel';

export interface Options {
  wellName?: string;
  apiNumber?: string;
}

export const plugin = new PanelPlugin<Options>(MainPanel).setPanelOptions((builder) => {
  return builder
    .addTextInput({
      path: 'wellName',
      name: 'Well name',
      description: 'Used to filter Bradenhead values. If API number is also specified, only reports matching both API and well name will be returned.',
      defaultValue: undefined,
    })
    .addTextInput({
      path: 'apiNumber',
      name: 'API number',
      description: 'Used to filter Bradenhead values. If well name is also specified, only reports matching both API and well name will be returned.',
      defaultValue: undefined,
    })
});
