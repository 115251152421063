import React from 'react';
import { GrafanaTheme2, PanelProps } from '@grafana/data';
import { Options } from '../module';
import { PanelDataErrorView, getBackendSrv } from '@grafana/runtime';
import { API_BASE_URL, PLUGIN_BASE_URL } from '../../constants';
import { css } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import { useAsync } from "react-use";
import { BradenheadTestReport } from 'sharedTypes';
import { getTagObjFromValue } from 'utils/utils.formatting';

interface Props extends PanelProps<Options> {}

export function MainPanel({ options, data, id }: Props) {
  const s = useStyles2(getStyles);

  const { value } = useAsync(() => {
    const backendSrv = getBackendSrv();
    return backendSrv.get(`${API_BASE_URL}/reports`, {well_name: options.wellName, api_number: options.apiNumber})
  });

  const reports: BradenheadTestReport[] = value?.data?.reports || [];

  return reports.length > 0 ? (
    <div style={{overflowY: 'scroll', height: '100%'}}>
      {reports.map((report) => (
        <li className={s.dashlistItem} key={report.id}>
          <div className={s.dashlistLink}>
            <div className={s.dashlistLinkBody}>
              <a
                className={s.dashlistTitle}
                href={`${PLUGIN_BASE_URL}/${report.type}/${report.id}`}
                target="_blank"
                rel="noreferrer"
              >
                {report.api_number}: {report.well_name}
              </a>
              <small style={{ marginLeft: '5px' }}>({report.type}, {new Date(report.datetime).toLocaleDateString()})</small>
              <div className={s.dashlistFolder}>
                {(getTagObjFromValue(report.status).icon || '🟡') + ' '}{report.status}
              </div>
            </div>
          </div>
        </li>
      ))}
    </div>
  ) : (
    <PanelDataErrorView panelId={id} data={data} />
  );
}

const getStyles = (theme: GrafanaTheme2) => ({
  dashlistLink: css`
    display: flex;
    cursor: pointer;
    border-bottom: 1px solid ${theme.colors.border.weak};
    margin-right: ${theme.spacing(1)};
    padding: ${theme.spacing(1)};
    align-items: center;

    &:hover {
      a {
        color: ${theme.colors.text.link};
        text-decoration: underline;
      }
    }
  `,
  dashlistTitle: css`
    &::after {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }
  `,
  dashlistLinkBody: css`
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  dashlistItem: css`
    position: relative;
    list-style: none;
  `,
  dashlistFolder: css`
    color: ${theme.colors.text.secondary};
    font-size: ${theme.typography.bodySmall.fontSize};
    line-height: ${theme.typography.body.lineHeight};
  `,
});
